var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"lined-textarea",class:{ 'validate-error': _vm.validateError ? true : false }},[(!_vm.disabled)?_c('div',{staticClass:"lined-textarea__lines",style:({ 'padding-right': _vm.longestWidth + 'px' })},[_c('div',{ref:"lines",staticClass:"lined-textarea__lines__inner"},_vm._l((_vm.lines),function(line,index){return _c('p',{key:index,staticClass:"lined-textarea__lines__line",class:{
                    'lined-textarea__lines__line--invalid':
                        _vm.invalidLines.includes(line),
                },domProps:{"innerHTML":_vm._s(line)}})}),0)]):_vm._e(),_c('textarea',_vm._b({directives:[{name:"model",rawName:"v-model",value:(_vm.content),expression:"content"},{name:"only-number-and-enter",rawName:"v-only-number-and-enter",value:(_vm.onlyNumberAndEnter),expression:"onlyNumberAndEnter"}],ref:"textarea",staticClass:"lined-textarea__content",class:{
            'lined-textarea__content--disabled': _vm.disabled,
            'lined-textarea__content--wrap': !_vm.nowrap,
            'lined-textarea__content--nowrap': _vm.nowrap,
        },style:(_vm.styles),attrs:{"disabled":_vm.disabled,"placeholder":_vm.placeholder},domProps:{"value":(_vm.content)},on:{"scroll":_vm.scrollLines,"input":[function($event){if($event.target.composing)return;_vm.content=$event.target.value},_vm.onInput],"mousedown":_vm.detectResize}},'textarea',_vm.$attrs,false)),_c('div',{ref:"helper",staticClass:"count-helper"})]),(_vm.validateError)?_c('b-form-invalid-feedback',{attrs:{"v-html":_vm.validateError}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }